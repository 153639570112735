<template>
<div>
  <button class="testBtn" @click="onPayHandler">微信支付</button>
</div>
</template>

<script>
import payUtils from '../utils/payUtil'

export default {
  name: 'PayTest',
  data() {
    return {}
  },
  mounted () {

  },
  methods: {
    onPayHandler(){
      payUtils.wechatPay('test', 'constantPayInfoService')
      .then(item => console.log(item)).catch(()=>{
        
      })
    }
  }

}
</script>

<style scoped>
.testBtn {
  width: 500px;
  height: 100px;
}
</style>